import React, { useState } from "react";
import Preloader from "./components/Preloader/Preloader";
import Subscribe from "./components/Subscribe/Subscribe";
import TypingTerminal from "./components/TypingTerminal/TypingTerminal";
import { SocialIcon } from 'react-social-icons';

// import logo from './logo.svg';
import './App.css';

//https://medium.com/developer-rants/what-if-height-100vh-is-bigger-than-your-screen-7f39c62ac170

const AMOUNT = Math.random()*10;
const MULTIPLY = Math.random()*10;
const TOTAL = Math.ceil(MULTIPLY * AMOUNT);
// var GIBBERISH = ""
// for (var i = 0; i < TOTAL; i++) {
//   GIBBERISH += Math.random().toString(36).substring(7);
// }
// console.log(GIBBERISH);

function getString() {
  var str = "";
  for (var counter = 0; counter <= TOTAL; counter++) {
    var randomNum = 0 + parseInt(Math.random() * 127);
    if (randomNum > 33) {
      str += String.fromCharCode(randomNum);
    } else {
      counter--;
    }
  }
  return str;
}

const GIBBERISH = getString();
//console.log(GIBBERISH);

function App() {
  const [LoaderCompleted, setLoaderCompleted] = useState(false);
  function setLoaderCompletedCallback (completed)  { setLoaderCompleted(completed); }

  return (
    <div className="App">
      <Preloader setLoaderCompleted={setLoaderCompletedCallback} />
      <div className="container">
        <TypingTerminal
          startSign={LoaderCompleted}
          repeat={false}
          heading={'...'}
          dataText={["CONNECTING.....                     \n",GIBBERISH+"             ","Welcome!\nThis website is still under construction.\nPlease subscribe to be notified when we come online.\n/disconnect"]} />
        <h1>Under construction</h1>
        <p>Our website is still in development. We will be here soon, subscribe to be notified!</p>
        <Subscribe />
      </div>
      <footer>
        <SocialIcon url="https://www.linkedin.com/in/wytse-talsma/" />
        <SocialIcon url="mailto://w.talsma@devta.nl" />
      </footer>
    </div>
  );
}

export default App;
